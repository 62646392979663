import './index.scss'
// import '../../assets/scripts/myNiceScroll'
// import {resizeScroll} from '../../assets/scripts/myNiceScroll'
import Swiper from 'swiper/swiper-bundle.min.js'
import '../../assets/scripts/jquery.waypoints.min.js'
import '../../assets/scripts/jquery.countup.min.js'

$('.counter').countUp({time:600});

$(document).ready(function(){
  // banner视频
  var $video = $('.index-banner video')
  $video.each(function(index){
    this.addEventListener('play', function () { //播放开始执行的函数
      $(this).closest('.video').find('.video-play').hide()
    });
    this.addEventListener('pause', function () { //暂停开始执行的函数
      $(this).closest('.video').find('.video-play').show()
    });
  })
  $('.index-banner .video .video-play').on('click',function(){
    let $parent = $(this).closest('.video')
    $parent.find('video')[0].play()
    $(this).hide()
  })

  // 新闻
  var newswiper = new Swiper(".section3 .news-swiper", {
    allowTouchMove: false,
    effect : 'fade',
    fadeEffect: {
      crossFade: true,
    },
    on:{
      init: function(swiper){
        $('.section3 .sec3-title .swiper-tab span').eq(this.realIndex).addClass('active')
      },
      slideChangeTransitionStart: function(){
        $('.section3 .sec3-title .swiper-tab span').eq(this.realIndex).addClass('active').siblings().removeClass('active')
      },
    },
  })

  $('.section3 .sec3-title .swiper-tab span').hover(function(){
    let index = $(this).index()
    newswiper.slideTo(index)
  })

  // 集团动态
  var newswiper_text1 = new Swiper(".section3 .group-news .mySwiper", {
    speed: 600,
    autoplay: { // 自动切换
      delay: 3000,
      stopOnLastSlide: false,
      disableOnInteraction: false,
    },
    on:{
      init: function(swiper){
        $('.section3 .group-news .swiper-tab li').eq(0).addClass('active')
      },
      slideChangeTransitionStart: function(){
        $('.section3 .group-news .swiper-tab li').eq(this.realIndex).addClass('active').siblings().removeClass('active')
      },
    },
  })
  $('.section3 .group-news .swiper-tab li').hover(function(){
    let index = $(this).index()
    newswiper_text1.slideTo(index)
  })
  
  // 产品交付动态
  var newswiper_text2 = new Swiper(".section3 .pro-news .mySwiper", {
    speed: 600,
    autoplay: { // 自动切换
      delay: 3000,
      stopOnLastSlide: false,
      disableOnInteraction: false,
    },
    on:{
      init: function(swiper){
        $('.section3 .pro-news .swiper-tab li').eq(0).addClass('active')
      },
      slideChangeTransitionStart: function(){
        $('.section3 .pro-news .swiper-tab li').eq(this.realIndex).addClass('active').siblings().removeClass('active')
      },
    },
  })
  $('.section3 .pro-news .swiper-tab li').hover(function(){
    let index = $(this).index()
    newswiper_text2.slideTo(index)
  })

  // 解决方案
  var solutionswiper = new Swiper(".section4 .sec4-swiper .mySwiper", {
    loop: true,
    slidesPerView : 1.2,
    spaceBetween : 20,
    pagination: {// 分页
      el: ".section4 .swiper-contral .swiper-pagination",
      type : 'progressbar',
    },
    navigation: {//按钮
      nextEl: ".section4 .swiper-contral .swiper-button-next",
      prevEl: ".section4 .swiper-contral .swiper-button-prev",
    },
    breakpoints: {
      768: {
        spaceBetween: 20,
        slidesPerView : 2.5,
      },
      1280: {
        spaceBetween : 50,
        slidesPerView : 3.5,
      }
    },
    on: {
      resize: function(){
        this.update();
      }, 
    },  
  })

  // 科技创新
  var kejiswiper_img = new Swiper(".section5 .bg-img .mySwiper", {
    allowTouchMove: false, 
    effect : 'fade',
  })
  var kejiswiper_text = new Swiper(".section5 .text-swiper .mySwiper", {
    allowTouchMove: false, 
    effect : 'fade',
    fadeEffect: {
      crossFade: true,
    },
    on:{
      init: function(swiper){
        $('.section5 .tab a').eq(this.realIndex).addClass('active')
      },
      slideChangeTransitionStart: function(){
        $('.section5 .tab a').eq(this.realIndex).addClass('active').siblings().removeClass('active')
      },
    },
  })

  var sec5mouseover_tid = [];
  var sec5mouseout_tid = [];
  $(document).ready(function () {
    $(".section5 .tab a").each(function (index) {
      $(this).hover(
        // 鼠标进入
        function () {
          var _self = this;
          // 停止卷起事件
          clearTimeout(sec5mouseout_tid[ index ]);
          // 当鼠标进入超过 0.2 秒, 展开菜单, 并记录到线程 ID 中
          sec5mouseover_tid[ index ] = setTimeout(function () {
            kejiswiper_img.slideTo(index)
            kejiswiper_text.slideTo(index)
            $('.counter').countUp({time:600});
          }, 201);
        },
        // 鼠标离开
        function () {
          var _self = this;
          // 停止展开事件
          clearTimeout(sec5mouseover_tid[ index ]);
          // 当鼠标离开超过 0.2 秒, 卷起菜单, 并记录到线程 ID 中
          sec5mouseout_tid[ index ] = setTimeout(function () {
          }, 201);
        });
      });
  });
})

